import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import * as m from '@/utils/messages';
import * as R from "ramda";
import * as React from 'react';
import Loading from '@/components/screens/Loading';
import Login from '@/templates/Login';
import NewTerms from '@/templates/NewTerms';
import useCCVOnly from "../hooks/useCCVOnly";

const LOADING = 'LOADING';
const NEW_TERMS = 'NEW_TERMS';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

export default function withAuth(Component, role) {
	const AuthWrapper = (props) => {
	  const [view, setView] = React.useState(LOADING);
	  const dispatch = useDispatch();
	  const { loggedIn, loginError, siteTermsUpdated, userType, accessToken } = useSelector(
			(state) => state.user
	  );
  
	  useCCVOnly();

		const handleAuth = async () => {
			const storedLoggedIn = window.localStorage.getItem("loggedIn") === "true" || loggedIn;

			const storedUser = JSON.parse(window.localStorage.getItem("user"));
			const storedUserType = storedUser?.userType || userType;
			
			if (siteTermsUpdated) {
				setView(NEW_TERMS);
				return;
			}
		
			if (role && role !== storedUserType) {
				setView(ERROR);
				return;
			}
		
			if (storedLoggedIn) {
				if (!accessToken) {
					await dispatch({
						type: "user/PERSIST_LOGIN",
					});
				}
				setView(SUCCESS);
			} else if (!storedLoggedIn || loginError) {
				dispatch({
					type: "user/LOGOUT",
				})
				navigate("/");
			} else {
				setView(LOADING);
			}
		};
  
	  React.useEffect(() => {
			handleAuth();
	  }, [siteTermsUpdated, loginError, role]);
		
	  if (view === LOADING) return <Loading>Loading profile...</Loading>;
	  if (view === NEW_TERMS) return <NewTerms />;
	  if (view === ERROR) return <div>Access Denied</div>;
  
	  return view === SUCCESS ? <Component {...props} /> : null;
	};
  
	return AuthWrapper;
  }
  